(function() {
	const currentScript = new URL(document.currentScript.src);

	window.initMHSConsent = function(pingToken) {
		const apiDomain = currentScript.hostname === 'qa.modernize.com' ? 'https://hsapiservice.quinstage.com/' : 'https://form-service-hs.qnst.com/',
			apiUrl = `${apiDomain}ping-post-encoded-consent/pings/${pingToken}/consent-searches`,
			targetElementId = 'mhs-consent',
			targetElement = document.getElementById(targetElementId);

		fetch(apiUrl, {'Accept': 'application/json'})
			.then(response => {
				return response.json();
			})
			.then(data => {
				let eventDetails = [];

				if (targetElement && targetElement.innerHTML.trim() !== '') {
					targetElement.innerHTML = '';
				}

				for(let i = 0; i < data.consentListings.length; i++) {
					const vendor = data.consentListings[i],
						checkboxWrapper = document.createElement('span'),
						label = document.createElement('label'),
						input = document.createElement('input');

					checkboxWrapper.className = 'client ' + vendor.displayName.replace(/[^a-zA-Z0-9\s]/g, '').toLowerCase().replace(/\s+/g, '-');

					input.className = 'consent-capture';
					input.name = 'consentCapture';
					input.type = 'checkbox';
					input.setAttribute('value', vendor.consentKey);
					input.checked = true;

					label.classList.add('client-label');
					label.appendChild(input);
					label.appendChild(document.createTextNode(vendor.displayName));

					checkboxWrapper.appendChild(label);

					const logoAndRating = {
						logo: vendor.logoUrl,
						rating: vendor.rating
					}
					eventDetails.push(logoAndRating);

					if (targetElement){
						targetElement.appendChild(checkboxWrapper);
					}
				}

				if (targetElement) {
					const consentLoaded = new CustomEvent('mhsConsentLoaded', { detail: eventDetails });
					window.dispatchEvent(consentLoaded);
				} else {
					console.log(`Error: could not find #${targetElementId} target element`);
				}
			})
			.catch(() => console.log('There was an error with your provided pingToken.'));
	};
})();
